$blue: #003952;

.accordion {
    .card {
        .collapse.show,
        .collapsing {
            background: white;
            color: black;
        }
        .card-header {
            padding: 0;
            border-radius: 0;
            button {
                // Expended
                background-color: $blue;
                color: white;
                border-radius: 0px;
                font-weight: 500;
                transition: 0.2s;
                &.collapsed {
                    // Normal
                    background-color: white;
                    color: black;
                    border-radius: 0px;
                    font-weight: 400;
                }
            }
        }
    }
}
