$theme-colors: (
  "primary": #273959,
  "secondary": #5493ff,
  "warning": #e0a934,
  "info": tomato,
  "white": #fff,
  "blue": #273959
);

$white: #fff;
$blue: #273959;
$grey: #939393;
$red: #df2935;
$green: #548c2f;
$orange: #f0803c;

// body {
//   //overflow: hidden;
// }

body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root > div {
  min-height: 100vh;
}

root {
  height: 100vh;
  min-width: 100vw;
}

.bold {
  font-weight: bolder;
}

.sidebar {
  align-items: stretch;
}

#content-wrapper {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.layoutContent {
  flex: 1 1 auto;
  background-color: $white;
  padding-top: 2em;
  padding-bottom: 2em;
  :only-child {
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {
  padding: 2rem 0;
  flex-shrink: 0;
  bottom: 0;
  width: 100%;
}

.nav-link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.sidebar-brand-text:hover {
  cursor: pointer;
  opacity: 0.5;
}

.bg-gradient-primary {
  background-color: #273959;
  background-image: linear-gradient(180deg, #273959 10%, #000 100%);
  background-size: cover;
}

.bg-login-image {
  background: url(https://source.unsplash.com/JXf2e0nb_-o/600x800) center;
  background-size: cover;
}

.carousel-control-prev {
  background-image: linear-gradient(-90deg, #f8f9fc 10%, #c2cbe5 100%);
  background-size: cover;
}

.carousel-control-next {
  background-image: linear-gradient(90deg, #f8f9fc 10%, #c2cbe5 100%);
  background-size: cover;
}

.accordionSort {
  width: 100%;
}

.collapse.in {
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.carousel-control-prev,
.carousel-control-next {
  color: black !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spanner {
  position: absolute;
  top: 50%;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  display: block;
  text-align: center;
  height: 300px;
  color: #fff;
  transform: translateY(-50%);
  z-index: 1000;
  cursor: wait; /* Add a pointer on hover */
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3); /* Black background with opacity */
  z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: wait; /* Add a pointer on hover */
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.spanner,
.overlay {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
img.home-view-individual-image {
  width: 85%;
  height: 90%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}
.mobilePage {
  position: absolute;
  width: 100% !important;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;

  padding-top: 5em;

  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.25); /* Black w/opacity */
  overflow: hidden;

  .body {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;

    .handle {
      margin: 0.3em 6em 0.3em 6em;
      background-color: $white;
      height: 0.3em;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      border-radius: 25px;
    }

    .handle:hover {
      background-color: darken($white, 60%);
      cursor: pointer;
    }

    .header {
      -webkit-border-top-left-radius: 25px;
      -webkit-border-top-right-radius: 25px;
      -moz-border-radius-topleft: 25px;
      -moz-border-radius-topright: 25px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;

      background-color: darken($white, 7%);

      overflow: hidden;

      padding-top: 0.5em;
      padding-bottom: 0.5em;

      -webkit-box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.24);
      box-shadow: 0px 5px 3px -3px rgba(0, 0, 0, 0.24);

      font-size: 16px;
      font-weight: bolder;
      margin-bottom: 0;
      p {
        font-size: 18px;
        font-weight: 800;
        font-family: "Montserrat";
        color: $blue;
        margin: 0;
      }
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .content {
      width: 100%;
      flex: 1 1 auto;

      background-color: $white;
      overflow-y: scroll;
      margin: 0 auto 0 auto;

      display: flex;
      flex-direction: column;

      form {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 80%;
        position: relative;
      }

      .list-group {
        width: 100%;

        .list-group-item {
          margin: 5% 5%;
          border-radius: 10px;
          border: none;
          width: 90%;
          box-shadow:
            1px 2px 2px #00000008,
            2px 4px 4px #00000008,
            4px 8px 8px #00000008,
            8px 16px 16px #00000008,
            16px 32px 32px #00000008;
          .bold {
            font-family: "Montserrat";
            color: $blue;
            font-weight: 700;
          }
          &.red {
            border-right: 20px solid $red;
            margin-bottom: 4px;
          }
          &.green {
            border-right: 7px solid $green;
            margin-bottom: 4px;
          }
        }
      }

      .imagesList {
        overflow-x: auto;
        width: 100%;
        img {
          object-fit: cover;
          max-height: 250px;
          height: auto;
        }
      }

      .divider {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: darken($white, 7%);

        .sectionTitle {
          font-size: 16px;
          font-weight: bolder;
          margin-bottom: 0;
        }
      }

      .validationSection {
        margin-top: auto;
        bottom: 0;
        padding: 0.5em;
      }
      &.content-details {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 15px;
        align-items: center;
        button {
          width: 90%;
          border-radius: 10px;
        }
        p {
          all: unset;
          margin: 1em auto;
          text-align: left;
        }
        .description {
          text-align: left;
          padding: 0 20px;
        }
      }
    }
  }
}

/////////////////////
/// HOME VIEW CSS ///
/////////////////////

$darker-light: #e6e6e6;
$dark: #8d8c8a;
$danger: #ff4747;
$warning: #f5a623;

.home-view-add-container {
  width: 50%;

  .home-view-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    vertical-align: middle; /* needed for Safari */

    .btn {
      border: $darker-light solid 2px;
      background-color: #eee;
      font-size: 20px;
      color: #777;

      &:focus {
        border-color: #71c016;
        box-shadow: none;
      }

      &:hover {
        background-color: #71c016;
        box-shadow: none;
      }
    }
  }

  .section {
    margin-top: 3%;
    border-top: 1px solid $dark;

    &:last-of-type {
      margin-bottom: 10vh;
    }

    .input-title {
      border-collapse: collapse;
      width: 100%;
      display: flex;

      div {
        border: 1px solid #ddd;
        vertical-align: middle; /* needed for Safari */
      }

      .area {
        width: 100%;
      }

      input {
        border: 0;
        display: block;
        width: 100%;
        padding: 8px;
        height: fit-content;
      }

      .btn-delete {
        border: $darker-light solid 2px;
        background-color: #eee;
        font-size: 17px;
        color: $danger;

        &:focus {
          border-color: $warning;
          box-shadow: none;
        }
      }
    }
    .category {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0rem 2rem;
      padding-bottom: 1rem;
      .name {
        width: 100%;
      }
      hr {
        width: 100%;
      }
      .input {
        margin-top: 1%;
        display: table;
        border-collapse: collapse;
        width: auto;

        div {
          display: table-cell;
          border: 1px solid #ddd;
          vertical-align: middle; /* needed for Safari */
        }

        .icon {
          background: #eee;
          color: #777;
          padding: 0 12px;
          width: 150px;
          text-align: center;
        }

        .area {
          width: auto;
          padding: 0 1rem;
        }

        input,
        textarea {
          border: 0;
          display: block;
          width: 100%;
          padding: 8px;
          resize: vertical;
        }
        textarea {
          height: 100%;
        }
        select {
          border: none;
          border-radius: 0;
        }
      }
      .type-textarea {
        width: 100%;
        .area {
          padding: 0;
        }
      }
      .type-dropdown {
        .area {
          padding: 0;
        }
      }
      .type-input {
        width: 100%;
      }

      .section-check {
        width: 100%;
        input {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }

  .btn-add {
    width: 100%;
    border: $darker-light solid 2px;
    background-color: #eee;
    font-size: 20px;
    color: #777;

    &:focus {
      border-color: #71c016;
      box-shadow: none;
    }
  }
  .form-checkbox:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  .form-checkbox:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  .form-checkbox[type="checkbox"] {
    border-radius: 0.25em;
  }

  .form-check .form-checkbox {
    float: left;
    margin-left: -1.5em;
  }
  .form-checkbox {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
  .home-view-add-container {
    width: 75%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 800px) {
  .home-view-add-container {
    width: 85%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .home-view-add-container {
    width: 100%;

    .home-view-header {
      .btn {
        &:focus {
        }
        &:hover {
        }
      }
    }
    .section {
      &:last-of-type {
      }
      .input-title {
        div {
        }

        .area {
        }

        input {
        }

        .btn-delete {
          &:focus {
          }
        }
      }
      .category {
        .name {
        }
        hr {
        }
        .input {
          div {
          }
          .icon {
          }
          .area {
          }
          input,
          textarea {
          }
          textarea {
          }
          select {
          }
        }
        .type-input {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          margin: 1rem 0;
          .icon {
            width: 100%;
            padding: 0.25rem 0;
          }
        }
        .type-dropdown {
          .area {
          }
        }
        .type-input {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          margin: 1rem 0;
          .icon {
            width: 100%;
            padding: 0.25rem 0;
          }
        }
        .section-check {
          input {
          }
        }
      }
    }
  }
}

@import "~bootstrap/scss/bootstrap.scss";
@import "Styles/calendar.scss";
