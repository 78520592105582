@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$blue: #003952;
.validate-inventory {
    margin-top: 1.5rem;
    width: 100%;
    overflow-x: hidden;

    h2 {
        text-align: center;
        color: $blue;
        font-weight: 700;
        font-family: "Montserrat";
    }
    h4 {
        font-weight: 600;
        font-family: "Montserrat";
        color: $blue;
    }
    h5 {
        font-weight: 700;
        font-family: "Montserrat";
        color: $blue;
    }

    .inventory-notice {
        font-weight: 600;
        font-size: 1rem;
        font-family: "Montserrat";
        text-align: center;
        margin: 0;
    }
    .select-colour {
        font-family: "Montserrat";
        font-weight: 700;
        color: black;
        option {
            font-weight: 700;
        }
    }
    .hiddenInput {
        height: 1px;
        width: 1px;
        border: 0;
    }
    .signatures button {
        width: 100%;
    }
    .custom-checkbox label {
        white-space-collapse: break-spaces;
    }
}
.justify-content-evenly {
    justify-content: space-evenly;
}

.modal-body {
    div {
        gap: 0rem;
        position: relative;
        .sigPad {
            border-radius: 5px;
            border: 5px solid $blue;
            position: relative;
        }
    }
}
// show error when no signature?
[data-required]:invalid + p {
    color: red;
    font-weight: bold;
}
.montserrat {
    font-family: "Montserrat";
}
